import request from '@/utils/request'

export function checkUserHasDashboardPermission(params) {
  return request({
    url: '/api/KeShiHua/CheckUserHasDashboardPermission',
    params: params,
    method: 'get',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}

export function getAuth() {
  return request({
    url: '/api/KeShiHua/GetAuth',
    method: 'get',
    header: {
      'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
    },

  })
}

export function createUser () {
    return request({
      url: '/api/KeShiHua/CreateUser',
      method: 'post',
      header:{
        'Content-Type':'application/json;charset=utf-8'  //如果写成contentType会报错
      },
      
    })
  }