<template>
  <div class="container">
    <van-row type="flex" justify="center">
      <van-col span="24">
        <div style="text-align: center; margin-top: 25px; margin-bottom: 10px">
          <van-image :src="logo" />
        </div>
      </van-col>
    </van-row>
    <van-row type="flex" justify="center">
      <van-col span="24">
        <div style="text-align: center; height: 50px">中酿KM系统</div>
      </van-col>
    </van-row>

    <van-cell-group>
      <van-field
        @blur="checkuserName"
        label="被模拟人的用户名"
        size="large"
        v-model="loginForm.userName"
        placeholder="请输个人手机号或ZN开头的员工编号"
        :error-message="errMes.userName"
      ></van-field>

    </van-cell-group>
    <div class="btn-box">
      <van-button type="info" size="large" round block @click="login"
        >模拟登录</van-button
      >
    </div>

  </div>
</template>

<script>
//import Vue from 'vue';
import { Toast } from "vant";
import { mapMutations, mapGetters } from "vuex";
import { loginMock  } from "@/api/user";

import logo from "@/assets/logo.png";
import { setCookie } from '@/utils/cookie'
import { getAuth as getGrafanaAuth } from "@/api/keshihua";

export default {
  name: "mockUser",
  data() {
    return {
      showWX: false,
      appid: "",
      code: "",
      logo: logo,
      loginForm: {
        switchRemember: true,
        userName: "",
        passWord: "",
        code: "",
      },
      errMes: {
        userName: "",
        passWord: "",
      },
    };
  },

  created() {
    this.initData();
  },

  methods: {
    checkuserName() {
      if (!this.loginForm.userName) {
        this.errMes.userName = "用户名不能为空";
        return false;
      }
      this.errMes.userName = "";
      return true;
    },
   

    async login() {
      if (this.checkuserName() ) {
        const data = await loginMock(this.loginForm);
        if (data.data.success) {
          this.updateUser({ user: data.data.data });

          let grafanaAuthData=await  getGrafanaAuth();
          if(grafanaAuthData.data.success&&grafanaAuthData.data.data){
            setCookie('grafana_auth',grafanaAuthData.data.data,30);
          }

          let { redirectUrl } = this.$route.query;
          if (redirectUrl == undefined || redirectUrl.indexOf("login") > 0) {
            window.location.replace(window.location.origin);
          } else {
            window.location.replace(
              window.location.origin + "/#" + (redirectUrl || "/")
            );
          }

          if (
            data.data.scoreMessage !== undefined &&
            data.data.scoreMessage != ""
          ) {
            Toast({
              message: data.data.scoreMessage,
              icon: "/image/signin_point_add.png",
            });
          } else {
            Toast("登录成功");
          }
        } else {
          Toast(data.data.errorMessage);
        }
      }
    },
    ...mapMutations(["updateUser", "rememberUser"]),

    ...mapGetters(["getRememberUser"]),
  },

  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.query != from.query) {
        this.initData();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.btn-box {
  padding: 20px;
}
</style>
